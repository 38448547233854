import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_ICONS_BLOCK} from 'constants/blockNames';
import {
    getCloudinaryObject,
    convertEnvironmentLivongoLink,
} from 'utils/utilities';

import linkArrow from './images/link-arrow.png';

import * as styles from './ProgramIconsBlock.module.scss';

const COLUMN_TYPE = {
    singleColumn: 1,
    doubleColumn: 2,
    tripleColumn: 3,
};

const ProgramIconsBlock = props => {
    const {programIcons} = props;
    const programIconClasses = classNames(styles.programIconContainer, {
        [styles.singleColumn]: COLUMN_TYPE.singleColumn === programIcons.length, //depending on the number of column(or item) we have, each will be styled differently
    });

    return (
        <div id={PROGRAM_ICONS_BLOCK}>
            <div className={programIconClasses}>
                {programIcons.map(programIcon => {
                    const imageObject = getCloudinaryObject(programIcon.icon);
                    return (
                        <div
                            key={programIcon.title}
                            className={styles.programIcon}
                        >
                            <img
                                className={styles.programImg}
                                src={imageObject.url}
                                alt={programIcon.icon?.name}
                                width={programIcon.iconWidth}
                            />
                            {programIcon.title && (
                                <div className={styles.title}>
                                    {programIcon.title}
                                </div>
                            )}
                            {programIcon.description && (
                                <div className={styles.description}>
                                    {renderRichText(programIcon.description)}
                                </div>
                            )}
                            {programIcon.link && (
                                <a
                                    href={convertEnvironmentLivongoLink(
                                        programIcon.link
                                    )}
                                >
                                    {programIcon.linkText}
                                    <img
                                        src={linkArrow}
                                        className={styles.linkArrow}
                                        width={15}
                                        height={15}
                                        alt=""
                                    />
                                </a>
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default ProgramIconsBlock;

export const query = graphql`
    fragment ProgramIconsBlock on ContentfulProgramIconsBlock {
        id
        name
        programIcons {
            name
            title
            description {
                raw
            }
            icon {
                ...CloudinaryImage
            }
            iconWidth
            linkText
            link
        }
    }
`;
