import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {PROGRAM_BENEFITS_BLOCK} from 'constants/blockNames';
import {getCloudinaryObject} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './ProgramBenefitsBlock.module.scss';

const BULLET_POINT_TYPE = {
    CHECK_MARK: 'CHECK_MARK',
    NUMBERING: 'NUMBERING',
};
const ALIGN_CTA_BTN = {
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
};

const ALIGN_TITLE = {
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
    BOTTOM_LEFT: 'BOTTOM_LEFT',
};

const DISPLAY_BULLET_TITLE = {
    LARGE_TEXT: 'LARGE_TEXT',
    SMALL_TEXT: 'SMALL_TEXT',
};

const ProgramBenefitsBlock = props => {
    const {
        alignCtaBtn = ALIGN_CTA_BTN.RIGHT,
        alignTitle = ALIGN_TITLE.RIGHT,
        backgroundBlock,
        benefits,
        bulletPointType = BULLET_POINT_TYPE.CHECK_MARK,
        content,
        ctaButton,
        displayBulletTitle = DISPLAY_BULLET_TITLE.LARGE_TEXT,
        disclaimer,
        image,
        title,
    } = props;
    const imageObject = getCloudinaryObject(image);

    const circleClasses = classNames(styles.circle, {
        [styles.CHECK_MARK]: BULLET_POINT_TYPE.CHECK_MARK === bulletPointType,
        [styles.NUMBERING]: BULLET_POINT_TYPE.NUMBERING === bulletPointType,
    });

    return (
        <BackgroundBlock id={PROGRAM_BENEFITS_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                <div className={styles.leftSide}>
                    {alignTitle === ALIGN_TITLE.LEFT && title && (
                        <h1 className={styles.title}>{title}</h1>
                    )}

                    {image && (
                        <img
                            src={imageObject?.url}
                            alt={image?.name}
                            className={styles.image}
                        />
                    )}

                    {alignTitle === ALIGN_TITLE.BOTTOM_LEFT && title && (
                        <h1 className={styles.title}>{title}</h1>
                    )}

                    {content && (
                        <div className={styles.content}>
                            {renderRichText(content)}
                        </div>
                    )}

                    {alignCtaBtn === ALIGN_CTA_BTN.LEFT ? (
                        <div>
                            {ctaButton && (
                                <CTAButton
                                    id="ctaWhatYouGet"
                                    className={styles.ctaButton}
                                    {...ctaButton}
                                />
                            )}
                        </div>
                    ) : (
                        ''
                    )}
                </div>

                <div className={styles.rightSide}>
                    {alignTitle === ALIGN_TITLE.RIGHT && title && (
                        <h1 className={styles.title}>{title}</h1>
                    )}

                    {benefits?.map((benefit, i) => (
                        <div key={i} className={styles.benefitContainer}>
                            <div className={styles.bulletType}>
                                {bulletPointType ===
                                BULLET_POINT_TYPE.CHECK_MARK ? (
                                    <div className={circleClasses}>
                                        <span className={styles.stem} />
                                        <span className={styles.kick} />
                                    </div>
                                ) : (
                                    <div className={circleClasses}>
                                        <span className={styles.digit}>
                                            {i + 1}
                                        </span>
                                    </div>
                                )}
                            </div>

                            <div className={styles.contentDetails}>
                                <div
                                    className={classNames(styles.programName, {
                                        [styles.smallText]:
                                            displayBulletTitle ===
                                            DISPLAY_BULLET_TITLE.SMALL_TEXT,
                                    })}
                                >
                                    {benefit.programName}
                                </div>

                                <span className={styles.sentenceFormat}>
                                    {benefit?.description}
                                </span>

                                <ul className={styles.bulletFormat}>
                                    {benefit?.benefits?.map(
                                        (benefitList, j) => (
                                            <li key={j}>{benefitList}</li>
                                        )
                                    )}
                                </ul>
                            </div>
                        </div>
                    ))}

                    {alignCtaBtn === ALIGN_CTA_BTN.RIGHT
                        ? ctaButton && (
                              <CTAButton
                                  id="ctaWhatYouGet"
                                  className={styles.ctaButton}
                                  {...ctaButton}
                              />
                          )
                        : ''}

                    {disclaimer && (
                        <div className={styles.disclaimer}>
                            {renderRichText(disclaimer)}
                        </div>
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default ProgramBenefitsBlock;

export const query = graphql`
    fragment ProgramBenefitsBlock on ContentfulProgramBenefitsBlock {
        name
        title
        content {
            raw
        }
        benefits {
            description
            programName
            benefits
        }
        ctaButton {
            ...CTAButton
        }
        disclaimer {
            raw
        }
        image {
            ...CloudinaryImage
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        bulletPointType
        alignCtaBtn
        alignTitle
        displayBulletTitle
    }
`;
