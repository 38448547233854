import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {DEVICE_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './DeviceBlock.module.scss';

const CUSTOMIZATIONS = {
    DPPWM_STYLE: 'DPPWM_STYLE',
    IMAGE_ON_LEFT: 'IMAGE_ON_LEFT',
};

const DEFAULT_LIST_ITEM_ICON_SIZE = 30;

const DeviceBlock = props => {
    const {
        subtitle,
        title,
        contentText,
        listItemsWithImages,
        backgroundBlock,
        customization,
    } = removeNullFromProps(props);

    return (
        <BackgroundBlock id={DEVICE_BLOCK} {...backgroundBlock}>
            <div
                className={classNames(styles.root, {
                    [styles.imageOnLeft]:
                        customization === CUSTOMIZATIONS.IMAGE_ON_LEFT,
                })}
            >
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                {contentText && (
                    <div className={styles.content}>
                        {renderRichText(contentText)}
                    </div>
                )}
                <div className={styles.listItems}>
                    {listItemsWithImages &&
                        listItemsWithImages.map(item => {
                            const iconURL = getCloudinaryImageUrl(item.image);
                            const imageWidth = item.imageWidth || 100;
                            return (
                                <div key={item.id} className={styles.listItem}>
                                    {iconURL && (
                                        <img
                                            style={{
                                                width: `${
                                                    DEFAULT_LIST_ITEM_ICON_SIZE *
                                                    (imageWidth / 100)
                                                }px`,
                                            }}
                                            src={iconURL}
                                            alt={item.image.name}
                                        />
                                    )}

                                    <span className={styles.listItemContent}>
                                        {item.content}
                                    </span>
                                </div>
                            );
                        })}
                </div>
            </div>
        </BackgroundBlock>
    );
};

export default DeviceBlock;

// Had to use an alias with content because it was conflicting type with other content on other blocks, should probably convert to Rich Text Type
export const query = graphql`
    fragment DeviceBlock on ContentfulDeviceBlock {
        id
        name
        subtitle
        title
        contentText {
            raw
        }
        customization
        listItemsWithImages {
            id
            content
            name
            image {
                ...CloudinaryImage
            }
            imageWidth
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
