import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {SIGNUP_BLOCK} from 'constants/blockNames';
import CTAButton from 'components/ctaButton';
import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './SignupBlock.module.scss';

const SignupBlock = ({backgroundBlock, content, ctaButton, title}) => {
    return (
        <BackgroundBlock id={SIGNUP_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {title && (
                    <div className={classNames(styles.title)}>{title}</div>
                )}
                {content && (
                    <div className={classNames(styles.content)}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <CTAButton id="ctaButton" {...ctaButton}></CTAButton>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default SignupBlock;

export const query = graphql`
    fragment SignupBlock on ContentfulSignupBlock {
        id
        name
        title
        content {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
