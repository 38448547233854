import React, {useContext} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';

import {RATING_BLOCK} from 'constants/blockNames';
import {LanguageContext} from 'hook/LanguageContext';

import BackgroundBlock from 'components/backgroundBlock';

import thumbsUp from './images/facebook-thumps-up.png';
import facebookLogo from './images/facebook-logo.svg';
import appStoreLogo from './images/app-store-logo.png';
import * as styles from './RatingBlock.module.scss';

const APP_STORE_LINK = 'https://apps.apple.com/us/app/livongo/id991595088';
const FACEBOOK_LINK = 'https://www.facebook.com/livongo/';

const RatingBlock = props => {
    const {
        title,
        ratingAboveTitle,
        appStoreRating,
        appStoreReviewCount,
        facebookRating,
        facebookLikeCount,
        backgroundBlock,
    } = props;

    const {languageText} = useContext(LanguageContext);

    const addCommaToNumber = x => {
        return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    };

    return (
        <BackgroundBlock id={RATING_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {!isNaN(ratingAboveTitle) && (
                    <StarRating rating={ratingAboveTitle} />
                )}
                {title && <div className={styles.title}>{title}</div>}
                <div className={styles.appsContainer}>
                    {!isNaN(appStoreRating) && (
                        <a
                            aria-label={`Rating of this product is ${appStoreRating} out of 5.`}
                            href={APP_STORE_LINK}
                            target="_blank"
                            className={styles.socialLink}
                        >
                            <div className={styles.appStoreContainer}>
                                <div className={styles.row}>
                                    <div className={styles.rating}>
                                        {appStoreRating}
                                    </div>
                                    <div className={styles.starBox}>
                                        <StarRating rating={appStoreRating} />
                                        <div className={styles.reviewCount}>
                                            {addCommaToNumber(
                                                appStoreReviewCount
                                            )}
                                            + {languageText['reviews']}
                                        </div>
                                    </div>
                                </div>
                                <div className={styles.logoBox}>
                                    <img
                                        src={appStoreLogo}
                                        width={80}
                                        height={20}
                                        className={styles.appleLogo}
                                        alt=""
                                    />
                                </div>
                            </div>
                        </a>
                    )}
                    {!isNaN(facebookRating) && (
                        <a
                            aria-label={`Rating of this product is ${facebookRating} out of 5.`}
                            href={FACEBOOK_LINK}
                            target="_blank"
                            className={styles.socialLink}
                        >
                            <div className={styles.facebookContainer}>
                                <div className={styles.row}>
                                    <img
                                        src={thumbsUp}
                                        width={70}
                                        height={0.83057 * 70}
                                        className={styles.thumbsUp}
                                        alt=""
                                    />
                                    <div className={styles.starBox}>
                                        <StarRating rating={facebookRating} />
                                        <div className={styles.reviewCount}>
                                            {addCommaToNumber(
                                                facebookLikeCount
                                            )}
                                            + {languageText['likes']}
                                        </div>
                                    </div>
                                </div>
                                <img
                                    src={facebookLogo}
                                    width={100}
                                    className={styles.facebookLogo}
                                    alt=""
                                />
                            </div>
                        </a>
                    )}
                </div>
            </div>
        </BackgroundBlock>
    );
};

const StarRating = ({rating}) => {
    //If the decimal is 0.9, 0.8, or 0.7, bring it down to 0.6 since it will be easier to see
    const reduceStarRating = oldRating => {
        const decimal = Number((oldRating % 1).toFixed(4));

        if (decimal === 0.9) {
            return oldRating - 0.3;
        } else if (decimal === 0.8) {
            return oldRating - 0.2;
        } else if (decimal % 1 === 0.7) {
            return oldRating - 0.1;
        }
        return rating;
    };

    return (
        <div
            className={styles.stars}
            style={{
                ['--rating']: reduceStarRating(rating),
            }}
        />
    );
};

export default RatingBlock;

export const query = graphql`
    fragment RatingBlock on ContentfulRatingBlock {
        id
        name
        title
        ratingAboveTitle
        appStoreRating
        appStoreReviewCount
        facebookRating
        facebookLikeCount
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
