import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {CTA_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './CTABlock.module.scss';

const TEXT_ALIGN = {
    LEFT: 'LEFT',
    CENTER: 'CENTER',
    RIGHT: 'RIGHT',
};

const CUSTOMIZATIONS = {
    NORMAL: 'NORMAL',
    SMALLER_STYLE: 'SMALLER_STYLE',
};

const CTABlock = props => {
    const {
        subtitle,
        title,
        content,
        ctaButton,
        contactInformation,
        textAlign = TEXT_ALIGN.CENTER,
        backgroundBlock,
        customization,
        fontFamily,
        // contactDescription,
    } = props;

    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile;

    return (
        <BackgroundBlock id={CTA_BLOCK} {...backgroundBlock}>
            <div
                setfont={fontFamily}
                className={classNames(styles.root, {
                    [styles.smallerStyle]:
                        customization === CUSTOMIZATIONS.SMALLER_STYLE,
                    [styles.left]: textAlign === TEXT_ALIGN.LEFT,
                    [styles.right]: textAlign === TEXT_ALIGN.RIGHT,
                    [styles.hasMobileCtaButton]: isMobile && ctaButton,
                })}
            >
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}

                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {contactInformation && (
                    <div className={styles.contactInformation}>
                        {renderRichText(contactInformation)}
                    </div>
                )}
                {ctaButton && (
                    <CTAButton
                        id="ctaButton"
                        isMobile={isMobile}
                        {...ctaButton}
                    ></CTAButton>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default CTABlock;

export const query = graphql`
    fragment CTABlock on ContentfulCtaBlock {
        name
        subtitle
        title
        content {
            raw
        }
        contactInformation {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        textAlign
        backgroundImage {
            ...CloudinaryImage
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        customization
        fontFamily
    }
`;
