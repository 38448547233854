// extracted by mini-css-extract-plugin
export var LEFT = "CarouselBlock-module--LEFT--R94yl";
export var RIGHT = "CarouselBlock-module--RIGHT--AoC-9";
export var appleStore = "CarouselBlock-module--apple-store--cMbwv";
export var btnItems = "CarouselBlock-module--btn-items--dcZFC";
export var card = "CarouselBlock-module--card--S4z6c";
export var cardDetails = "CarouselBlock-module--card-details--70l0x";
export var contactInfo = "CarouselBlock-module--contact-info--dvOyX";
export var container = "CarouselBlock-module--container--uB1Ux";
export var content = "CarouselBlock-module--content--Hn83H";
export var ctaBtn = "CarouselBlock-module--cta-btn--owTuQ";
export var ctaButton = "CarouselBlock-module--cta-button--5FPfb";
export var disclaimer = "CarouselBlock-module--disclaimer--CptBs";
export var flexContainer = "CarouselBlock-module--flex-container--d-v28";
export var googlePlay = "CarouselBlock-module--google-play--lqQUs";
export var happyfaceEmoji = "CarouselBlock-module--happyface-emoji--WdBB6";
export var imageContainer = "CarouselBlock-module--image-container--gDTuG";
export var items = "CarouselBlock-module--items--xEbva";
export var slickActive = "CarouselBlock-module--slick-active--g3Qmi";
export var slider = "CarouselBlock-module--slider--Q59jp";
export var title = "CarouselBlock-module--title--nZ2dn";
export var verticalDots = "CarouselBlock-module--vertical-dots--7KLls";