// extracted by mini-css-extract-plugin
export var BLUE = "WhatYouGetBlock-module--BLUE--Iw46I";
export var GREEN = "WhatYouGetBlock-module--GREEN--bfMJT";
export var badgePrice = "WhatYouGetBlock-module--badge-price--GhSa3";
export var badgeRecurance = "WhatYouGetBlock-module--badge-recurance--o-7o0";
export var content = "WhatYouGetBlock-module--content--MUFna";
export var costBadge = "WhatYouGetBlock-module--cost-badge--4tmkB";
export var ctaButton = "WhatYouGetBlock-module--cta-button--UIzpF";
export var disclaimer = "WhatYouGetBlock-module--disclaimer--iaHeY";
export var dppContact = "WhatYouGetBlock-module--dpp-contact--kvqFI";
export var dppContainer = "WhatYouGetBlock-module--dpp-container--qjl4c";
export var dppCtaBtn = "WhatYouGetBlock-module--dpp-cta-btn--lqZnc";
export var imageOnLeft = "WhatYouGetBlock-module--image-on-left--5+T7z";
export var left = "WhatYouGetBlock-module--left--odsR4";
export var listItem = "WhatYouGetBlock-module--list-item--Hr9Eg";
export var listItems = "WhatYouGetBlock-module--list-items--F-j+H";
export var listItemsContainer = "WhatYouGetBlock-module--list-items-container--7nuDD";
export var right = "WhatYouGetBlock-module--right--zwGLe";
export var root = "WhatYouGetBlock-module--root--Zsj08";
export var subtitle = "WhatYouGetBlock-module--subtitle--WgbUl";
export var title = "WhatYouGetBlock-module--title--0cpmj";
export var titlesContainer = "WhatYouGetBlock-module--titles-container--zcFm3";