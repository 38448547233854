import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {FOCUS_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './FocusBlock.module.scss';

const BG_COLOR = {
    BABY_BLUE: 'BABY_BLUE',
    LIGHT_WHITE: 'LIGHT_WHITE',
    WHITE: 'WHITE',
};

const CIRCLE_ICON = {
    BLUE_CIRCLE: 'BLUE_CIRCLE',
    GREEN_CIRCLE: 'GREEN_CIRCLE',
};
const COLUMN = {
    ONE: 'ONE',
    TWO: 'TWO',
};

const FocusBlock = props => {
    const {
        backgroundColor,
        backgroundBlock,
        ctaButton,
        content,
        circleIcon = CIRCLE_ICON.GREEN_CIRCLE,
        displayColumn,
        points,
        subtitle,
        title,
    } = props;
    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile;

    const rootClasses = classNames(styles.root, {
        [styles.babyBlue]: backgroundColor === BG_COLOR.BABY_BLUE,
        [styles.lightWhite]: backgroundColor === BG_COLOR.LIGHT_WHITE,
        [styles.white]: backgroundColor === BG_COLOR.WHITE,
    });
    const pointsClasses = classNames(styles.pointsContainer, {
        [styles.one]: displayColumn === COLUMN.ONE,
        [styles.two]: displayColumn === COLUMN.TWO,
    });
    const circleClasses = classNames(styles.point, {
        [styles.blueCircle]: circleIcon === CIRCLE_ICON.BLUE_CIRCLE,
        [styles.greenCircle]: circleIcon === CIRCLE_ICON.GREEN_CIRCLE,
    });

    return (
        <BackgroundBlock id={FOCUS_BLOCK} {...backgroundBlock}>
            <div id={FOCUS_BLOCK} className={rootClasses}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}
                {title && <div className={styles.title}>{title}</div>}
                <ul className={pointsClasses}>
                    {points?.map((point, i) => (
                        <li key={i} className={circleClasses}>
                            {point}
                        </li>
                    ))}
                </ul>
                {content && (
                    <div className={styles.content}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <div className={styles.ctaButton}>
                        <CTAButton
                            id="ctaFocus"
                            isMobile={isMobile}
                            {...ctaButton}
                        />
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default FocusBlock;

export const query = graphql`
    fragment FocusBlock on ContentfulFocusBlock {
        backgroundColor
        circleIcon
        displayColumn
        points
        name
        subtitle
        title
        content {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
