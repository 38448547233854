// extracted by mini-css-extract-plugin
export var contactInfoBlueText = "CTABlock-module--contact-info-blue-text--zdz30";
export var contactInformation = "CTABlock-module--contact-information--a8uIQ";
export var content = "CTABlock-module--content--hZsAi";
export var dppRoot = "CTABlock-module--dpp-root--h47Qu";
export var hasMobileCtaButton = "CTABlock-module--has-mobile-cta-button--w92Lm";
export var left = "CTABlock-module--left--DChrh";
export var right = "CTABlock-module--right--lV6Vt";
export var root = "CTABlock-module--root--1-W2E";
export var smallerStyle = "CTABlock-module--smaller-style--GG4SV";
export var subtitle = "CTABlock-module--subtitle--uoaY7";
export var title = "CTABlock-module--title--bntRk";