const HERO_BLOCK = 'HERO_BLOCK';
const HERO_BLOCK_MOBILE = 'HERO_BLOCK_MOBILE';
const ANIMATION_BLOCK = 'ANIMATION_BLOCK';
const APP_BLOCK = 'APP_BLOCK';
const BREAKING_DOWN_BARRIERS_BLOCK = 'BREAKING_DOWN_BARRIERS_BLOCKS';
const CAROUSEL_BLOCK = 'CAROUSEL_BLOCK';
const CTA_BLOCK = 'CTA_BLOCK';
const DEVICE_BLOCK = 'DEVICE_BLOCK';
const FAQ_BLOCK = 'FAQ_BLOCK';
const FOCUS_BLOCK = 'FOCUS_BLOCK';
const PERSONALIZED_SOLUTION_BLOCK = 'PERSONALIZED_SOLUTION_BLOCK';
const PROGRAM_BENEFITS_BLOCK = 'PROGRAM_BENEFITS_BLOCK';
const PROGRAM_OVERVIEW_BLOCK = 'PROGRAM_OVERVIEW_BLOCK';
const PROGRAM_ICONS_BLOCK = 'PROGRAM_ICONS_BLOCK';
const RATING_BLOCK = 'RATING_BLOCK';
const SUPPLY_BLOCK = 'SUPPLY_BLOCK';
const TESTIMONIALS_BLOCK = 'TESTIMONIALS_BLOCK';
const VIDEO_BLOCK = 'VIDEO_BLOCK';
const WHAT_YOU_GET_BLOCK = 'WHAT_YOU_GET_BLOCK';
const GUIDELINE_BLOCK = 'GUIDELINE_BLOCK';
const SIGNUP_BLOCK = 'SIGNUP_BLOCK';

export {
    HERO_BLOCK,
    HERO_BLOCK_MOBILE,
    ANIMATION_BLOCK,
    APP_BLOCK,
    BREAKING_DOWN_BARRIERS_BLOCK,
    CAROUSEL_BLOCK,
    CTA_BLOCK,
    DEVICE_BLOCK,
    FAQ_BLOCK,
    FOCUS_BLOCK,
    PERSONALIZED_SOLUTION_BLOCK,
    PROGRAM_BENEFITS_BLOCK,
    PROGRAM_OVERVIEW_BLOCK,
    PROGRAM_ICONS_BLOCK,
    RATING_BLOCK,
    SUPPLY_BLOCK,
    TESTIMONIALS_BLOCK,
    VIDEO_BLOCK,
    WHAT_YOU_GET_BLOCK,
    GUIDELINE_BLOCK,
    SIGNUP_BLOCK,
};
