// extracted by mini-css-extract-plugin
export var appStoreContainer = "RatingBlock-module--app-store-container--HW-bX";
export var appStoreText = "RatingBlock-module--app-store-text--GzpIE";
export var appleLogo = "RatingBlock-module--apple-logo--MR2sV";
export var appsContainer = "RatingBlock-module--apps-container--ntvDA";
export var backgroundBlock = "RatingBlock-module--background-block--GZwly";
export var facebookContainer = "RatingBlock-module--facebook-container--rcflF";
export var facebookLogo = "RatingBlock-module--facebook-logo--6oBv-";
export var logoBox = "RatingBlock-module--logo-box--2myFo";
export var rating = "RatingBlock-module--rating--IFmVS";
export var reviewCount = "RatingBlock-module--review-count--uLyaZ";
export var root = "RatingBlock-module--root--elEex";
export var row = "RatingBlock-module--row--M6pN+";
export var socialLink = "RatingBlock-module--social-link--cwQ8B";
export var starBox = "RatingBlock-module--star-box--z3ZvM";
export var stars = "RatingBlock-module--stars--beFMs";
export var thumbsUp = "RatingBlock-module--thumbs-up--DpXkl";
export var title = "RatingBlock-module--title--dL1UM";