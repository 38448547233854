import React, {useRef, useState, useEffect, useContext} from 'react';
import classNames from 'classnames';
import htmlStringReactParser, {domToReact} from 'html-react-parser';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {HERO_BLOCK, HERO_BLOCK_MOBILE} from 'constants/blockNames';
import {ChatContext} from 'hook/ChatContext';
import useWindowSize from 'hook/useWindowSize';
import {GlobalContext} from 'hook/GlobalContext';
import {getCloudinaryObject} from 'utils/utilities';
import {removeNullFromProps} from 'utils/utilities';
import Header from 'components/header';
import CTAButton from 'components/ctaButton';

import * as styles from './HeroBlock.module.scss';

const BACKGROUND_IMAGE_ORIENTATION = {
    WHOLE_SCREEN: 'WHOLE_SCREEN',
    RIGHT: 'RIGHT',
};

const TITLE_SIZE = {
    MD: 'MD',
    LG: 'LG',
};

const HeroBlock = props => {
    return (
        <div id={HERO_BLOCK}>
            <div className={styles.desktopView}>
                <DesktopHeroBlock {...props} />
            </div>
            <div className={styles.mobileView}>
                <MobileHeroBlock {...props} />
            </div>
        </div>
    );
};

const DesktopHeroBlock = props => {
    const {
        alignContactInfo,
        content,
        contactInfo,
        ctaButton,
        watchVideoButton,
        backgroundImage,
        backgroundImageOrientation = BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN,
        backgroundImageTopPadding = 0,
        backgroundImageBackgroundColor = '#FFFFFF',
        headerInfo,
        title,
        titleSize = TITLE_SIZE.LG,
    } = removeNullFromProps(props);
    const {width: windowWidth} = useWindowSize();
    const ref = useRef(null);
    const [containerHeight, setContainerHeight] = useState(null);
    const {onClickChat} = useContext(ChatContext);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);

    useEffect(() => {
        if (ref.current) {
            setContainerHeight(ref.current.offsetHeight);
        }
    }, [ref.current]);

    const titleSizeClasses = classNames(styles.title, {
        [styles['MD']]: titleSize === TITLE_SIZE.MD,
        [styles['LG']]: titleSize === TITLE_SIZE.LG,
    });

    let backgroundImageObj = getCloudinaryObject(backgroundImage);
    let backgroundStyle = {
        backgroundImage: `url(${backgroundImageObj.url})`,
    };

    if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.WHOLE_SCREEN
    ) {
        backgroundStyle['backgroundSize'] = 'cover';
        backgroundStyle['backgroundPositionY'] = 0;

        const heroCurrentHeight =
            backgroundImageObj.height *
            (windowWidth / backgroundImageObj.width);

        // As the container stretches out and the image stretches out, this cuts off some of the unneed empty space at the top
        if (heroCurrentHeight > containerHeight) {
            backgroundStyle['backgroundPositionY'] =
                -(backgroundImageTopPadding / 100) * heroCurrentHeight;
        }
    }
    // For an image that is right aligned, use 'contain' if the width of the container is wider then image otherwise use 'cover
    else if (
        backgroundImageOrientation === BACKGROUND_IMAGE_ORIENTATION.RIGHT
    ) {
        backgroundStyle['backgroundSize'] =
            windowWidth / containerHeight >
            backgroundImageObj.width / backgroundImageObj.height
                ? 'contain'
                : 'cover';

        // For images where the left edge of the image is a different color then white
        backgroundStyle['backgroundColor'] = backgroundImageBackgroundColor;
        backgroundStyle['backgroundPositionY'] = backgroundImageTopPadding;
    }

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            if (attribs.id === 'chat') {
                const targetChildren = children.filter(child => {
                    return child.data && child.data.indexOf('chat with us');
                });
                const remainingChildren = children.filter(child => {
                    return (
                        !child.data ||
                        !(child.data && child.data.indexOf('chat with us'))
                    );
                });

                return (
                    <>
                        <span onClick={onClickChat}>
                            {domToReact(targetChildren, options)}
                        </span>
                        {domToReact(remainingChildren, options)}
                    </>
                );
            } else if (attribs.id === 'phoneNumber') {
                return <a href={contactPhoneURL}>{contactNumber}</a>;
            }
        },
    };
    return (
        <div
            className={classNames(styles.heroImage)}
            ref={ref}
            style={backgroundStyle}
        >
            <div className={classNames(styles.container)}>
                <Header {...headerInfo} isMobile={false} />

                <div className={styles.subContainer}>
                    <h1 className={titleSizeClasses}>
                        {title && renderRichText(title)}
                    </h1>

                    {content && (
                        <div className={classNames(styles.content)}>
                            {renderRichText(content)}
                        </div>
                    )}

                    <div
                        className={classNames(styles.vertically, {
                            [styles.horizontally]:
                                alignContactInfo === 'horizontally',
                        })}
                    >
                        {ctaButton && (
                            <CTAButton
                                className={styles.ctaBtn}
                                id="ctaHeroWeb"
                                {...ctaButton}
                            />
                        )}
                        {watchVideoButton && (
                            <div>
                                <CTAButton
                                    className={styles.ctaBtn}
                                    id="ctaWatchVideoWeb"
                                    {...watchVideoButton}
                                />
                            </div>
                        )}

                        {contactInfo && <div className={styles.pipeline}></div>}

                        <div className={styles.contactInfo}>
                            {contactInfo &&
                                htmlStringReactParser(
                                    contactInfo.childMarkdownRemark.html,
                                    options
                                )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

const MobileHeroBlock = props => {
    const {
        backgroundImage,
        contactInfo,
        content,
        ctaButton,
        watchVideoButton,
        headerInfo,
        mobileBackgroundImage,
        title,
    } = removeNullFromProps(props);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    let backgroundImageObj =
        getCloudinaryObject(mobileBackgroundImage) ||
        getCloudinaryObject(backgroundImage);

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            if (attribs.id === 'chat') {
                const targetChildren = children.filter(child => {
                    return child.data && child.data.indexOf('chat with us');
                });
                const remainingChildren = children.filter(child => {
                    return (
                        !child.data ||
                        !(child.data && child.data.indexOf('chat with us'))
                    );
                });

                return (
                    <>
                        <span onClick={onClickChat}>
                            {domToReact(targetChildren, options)}
                        </span>
                        {domToReact(remainingChildren, options)}
                    </>
                );
            } else if (attribs.id === 'phoneNumber') {
                return <a href={contactPhoneURL}>{contactNumber}</a>;
            }
        },
    };

    return (
        <div id={HERO_BLOCK_MOBILE} className={styles.mobileHeroContainer}>
            <Header {...headerInfo} isMobile />

            <div className={styles.container}>
                <div className={styles.subContainer}>
                    <h1 className={styles.title}>{renderRichText(title)}</h1>
                    {content && (
                        <div className={styles.content}>
                            {renderRichText(content)}
                        </div>
                    )}
                    {ctaButton && (
                        <div>
                            <CTAButton
                                className={styles.ctaBtn}
                                id="ctaHeroMobile"
                                {...ctaButton}
                            />
                        </div>
                    )}
                    {watchVideoButton && (
                        <div className={styles.videoButton}>
                            <CTAButton
                                className={styles.ctaBtn}
                                id="ctaWatchVideoMobile"
                                {...watchVideoButton}
                            />
                        </div>
                    )}
                    <div className={styles.contactInfo}>
                        {contactInfo &&
                            htmlStringReactParser(
                                contactInfo.childMarkdownRemark.html,
                                options
                            )}
                    </div>
                </div>
            </div>

            <div
                className={styles.mobileHeroBanner}
                style={{backgroundImage: `url(${backgroundImageObj.url})`}}
            />
        </div>
    );
};

export default HeroBlock;

export const query = graphql`
    fragment HeroBlock on ContentfulHeroBlock {
        id
        name
        headerInfo {
            cobrandLogo {
                ...CloudinaryImage
            }
            ctaLogin {
                ...CTAButton
            }
            ctaJoinNow {
                ...CTAButton
            }
        }
        title {
            raw
        }
        titleSize
        content {
            raw
        }
        contactInfo {
            contactInfo
            childMarkdownRemark {
                html
            }
        }
        alignContactInfo
        ctaButton {
            ...CTAButton
        }
        watchVideoButton {
            ...CTAButton
        }
        backgroundImage {
            ...CloudinaryImage
        }
        backgroundImageOrientation
        backgroundImageTopPadding
        backgroundImageBackgroundColor
        mobileBackgroundImage {
            ...CloudinaryImage
        }
        livongoLogo {
            ...CloudinaryImage
        }
        coBrandingLogo {
            ...CloudinaryImage
        }
    }
`;
