import React, {useState} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import { renderRichText } from "gatsby-source-contentful/rich-text";

import BackgroundBlock from 'components/backgroundBlock';
import {removeNullFromProps} from 'utils/utilities';
import {PROGRAM_OVERVIEW_BLOCK} from 'constants/blockNames';

import * as styles from './ProgramOverviewBlock.module.scss';

const ProgramOverviewBlock = props => {
    const {name, title, programs, backgroundBlock} = removeNullFromProps(props);
    const [openProgram, setOpenProgram] = useState(0);

    const onHover = index => {
        setOpenProgram(index);
    };

    return (
        <BackgroundBlock id={PROGRAM_OVERVIEW_BLOCK} {...backgroundBlock}>
            {title && <div className={styles.title}>{title}</div>}
            <div className={styles.programsContainer}>
                {programs.map((program, i) => (
                    <div
                        key={program.name}
                        onMouseEnter={() => {
                            onHover(i);
                        }}
                        className={classNames(styles.programCard, {
                            [styles.open]: openProgram === i,
                        })}
                    >
                        <div className={styles.programName}>
                            {program.programName}
                        </div>
                        <div className={styles.programDescription}>
                            {program.description}
                        </div>

                        <ul className={styles.programBenefits}>
                            {program.benefits.map(benefit => (
                                <li
                                    key={benefit}
                                    className={styles.programBenefit}
                                >
                                    {benefit}
                                </li>
                            ))}
                        </ul>
                        {program?.programDisclaimer && (
                            <div >
                                {renderRichText(program?.programDisclaimer)}
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </BackgroundBlock>
    );
};

export default ProgramOverviewBlock;

export const query = graphql`
    fragment ProgramOverviewBlock on ContentfulProgramOverviewBlock {
        name
        title
        programs {
            name
            programName
            description
            benefits
            programDisclaimer {
                raw
            }
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
