// extracted by mini-css-extract-plugin
export var CENTER = "FAQBlock-module--CENTER--hO0N4";
export var LEFT = "FAQBlock-module--LEFT--JXxY3";
export var answer = "FAQBlock-module--answer--zUJjo";
export var container = "FAQBlock-module--container--vvyFR";
export var content = "FAQBlock-module--content--05ZEl";
export var glypicon = "FAQBlock-module--glypicon--tIHXl";
export var minus = "FAQBlock-module--minus--V-87D";
export var openChat = "FAQBlock-module--open-chat--fBxvv";
export var plus = "FAQBlock-module--plus--x7Jes";
export var question = "FAQBlock-module--question--4PcqO";
export var questionSection = "FAQBlock-module--question-section--ry5rP";
export var questionsSection = "FAQBlock-module--questions-section--QiUTp";
export var section = "FAQBlock-module--section--4ZB4t";
export var show = "FAQBlock-module--show--TKz1N";
export var singleColumn = "FAQBlock-module--single-column--3rzev";
export var title = "FAQBlock-module--title---wWU9";