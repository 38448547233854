import React from 'react';
import classNames from 'classnames';

import {CAROUSEL_BLOCK} from 'constants/blockNames';
import {getCloudinaryImageUrl} from 'utils/utilities';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import BackgroundBlock from 'components/backgroundBlock';
import CTAButton from 'components/ctaButton';

import * as styles from './CarouselBlock.module.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const CarouselBlockMobile = props => {
    const {carouselCards, onClickBanner} = props;

    return (
        <BackgroundBlock id={CAROUSEL_BLOCK}>
            <div className={styles.container}>
                {carouselCards.map(card => {
                    const imageURL = getCloudinaryImageUrl(card.carouselImage);

                    return (
                        <div key={card.id}>
                            <div className={styles.flexContainer}>
                                {imageURL && (
                                    <img
                                        className={styles.imageContainer}
                                        src={imageURL}
                                        alt={card.carouselImage.name}
                                    />
                                )}

                                <div className={styles.card}>
                                    {card.subtitle && (
                                        <div>{card.subtitle}</div>
                                    )}
                                    {card.title && (
                                        <div className={styles.title}>
                                            {card.title}
                                        </div>
                                    )}
                                    {card.content && (
                                        <div className={styles.content}>
                                            {card.content.content}
                                        </div>
                                    )}
                                    {card.items && (
                                        <ul className={styles.items}>
                                            {card.items.map((list, i) => (
                                                <li key={i}>{list}</li>
                                            ))}
                                        </ul>
                                    )}

                                    <div className={styles.btnItems}>
                                        <div>
                                            {card.ctaAppleStore && (
                                                <button
                                                    id="ctaAppleStoreBanner"
                                                    aria-label="Apple App Store app link"
                                                    className={classNames(
                                                        styles.ctaButton,
                                                        styles.appleStore
                                                    )}
                                                    onClick={() =>
                                                        onClickBanner(
                                                            card?.ctaAppleStore
                                                                ?.url
                                                        )
                                                    }
                                                />
                                            )}

                                            {card.ctaGooglePlay && (
                                                <button
                                                    id="ctaGooglePlayBanner"
                                                    aria-label="Google Play Store app link"
                                                    className={classNames(
                                                        styles.ctaButton,
                                                        styles.googlePlay
                                                    )}
                                                    onClick={() =>
                                                        onClickBanner(
                                                            card?.ctaGooglePlay
                                                                ?.url
                                                        )
                                                    }
                                                />
                                            )}
                                        </div>

                                        {card.ctaButton && (
                                            <CTAButton
                                                className={styles.ctaBtn}
                                                {...card.ctaButton}
                                            />
                                        )}
                                    </div>

                                    <div className={styles.disclaimer}>
                                        {card.disclaimer &&
                                            renderRichText(card.disclaimer)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </BackgroundBlock>
    );
};

export default CarouselBlockMobile;
