import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import ReactPlayer from 'react-player';

import {ANIMATION_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryObject} from 'utils/utilities';

import CTAButton from 'components/ctaButton';
import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './AnimationBlock.module.scss';

const AnimationBlock = props => {
    const {title, content, ctaButton, video, backgroundBlock} = props;
    const {isMobile} = useWindowSize();

    const backgroundVideoObj = getCloudinaryObject(video);

    return (
        <BackgroundBlock id={ANIMATION_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {backgroundVideoObj && (
                    <ReactPlayer
                        url={backgroundVideoObj.url}
                        width={'100%'}
                        height={isMobile ? 300 : 500}
                        muted={true}
                        loop={true}
                        playing={true}
                    />
                )}
                {title && <div className={styles.title}>{title}</div>}
                {content && (
                    <div className={classNames(styles.content)}>
                        {renderRichText(content)}
                    </div>
                )}
                {ctaButton && (
                    <CTAButton id="ctaButton" {...ctaButton}></CTAButton>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default AnimationBlock;

// Had to use an alias with content because it was conflicting type with other content on other blocks, should probably convert to Rich Text Type
export const query = graphql`
    fragment AnimationBlock on ContentfulAnimationBlock {
        id
        name
        title
        content {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        video {
            ...CloudinaryVideo
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
