// extracted by mini-css-extract-plugin
export var babyBlue = "FocusBlock-module--baby-blue--ufdx+";
export var blueCircle = "FocusBlock-module--blue-circle--SjG0s";
export var content = "FocusBlock-module--content--HYU4x";
export var ctaButton = "FocusBlock-module--cta-button--khuBq";
export var greenCircle = "FocusBlock-module--green-circle--AWsXX";
export var lightWhite = "FocusBlock-module--light-white--scFyt";
export var one = "FocusBlock-module--one--Ny6n3";
export var point = "FocusBlock-module--point--JjfRl";
export var pointsContainer = "FocusBlock-module--points-container--qPntu";
export var root = "FocusBlock-module--root--WH-07";
export var subtitle = "FocusBlock-module--subtitle--xhvIj";
export var title = "FocusBlock-module--title--UWvHp";
export var two = "FocusBlock-module--two--8O6+N";
export var white = "FocusBlock-module--white--oMor+";