import React, {useContext} from 'react';
import classNames from 'classnames';
import htmlStringReactParser, {domToReact} from 'html-react-parser';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import Slider from 'react-slick';
import {graphql} from 'gatsby';

import {ctaClicked} from 'utils/mixpanel-utils';
import {getCloudinaryImageUrl, removeNullFromProps} from 'utils/utilities';
import useWindowSize from 'hook/useWindowSize';
import {ChatContext} from 'hook/ChatContext';
import {GlobalContext} from 'hook/GlobalContext';
import {CAROUSEL_BLOCK} from 'constants/blockNames';
import CarouselBlockMobile from './CarouselBlockMobile';
import BackgroundBlock from 'components/backgroundBlock';

import CTAButton from 'components/ctaButton';

import * as styles from './CarouselBlock.module.scss';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

const ALIGN_CAROUSEL = {
    RIGHT: 'RIGHT',
    LEFT: 'LEFT',
};

const CarouselBlock = props => {
    const {
        alignCarousel = ALIGN_CAROUSEL.LEFT,
        carouselCards,
        emoji,
    } = removeNullFromProps(props);
    const {contactNumber, contactPhoneURL} = useContext(GlobalContext);
    const {onClickChat} = useContext(ChatContext);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        lazyLoad: true,
        className: styles.slider,
        vertical: true,
        verticalSwiping: true,
        adaptiveHeight: true,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 8000,
        fade: true,
        appendDots: dots => (
            <div>
                <ul className={styles.verticalDots}> {dots} </ul>
            </div>
        ),
    };

    const windowSize = useWindowSize();
    const isMobile = windowSize.isMobile || windowSize.width === 768;

    const onClickBanner = url => {
        ctaClicked();

        window.open(url, '_self');
    };

    const options = {
        replace: ({attribs, children}) => {
            if (!attribs) {
                return;
            }

            if (attribs.id === 'chat') {
                const targetChildren = children.filter(child => {
                    return child.data && child.data.indexOf('chat with us');
                });
                const remainingChildren = children.filter(child => {
                    return (
                        !child.data ||
                        !(child.data && child.data.indexOf('chat with us'))
                    );
                });

                return (
                    <>
                        <span onClick={onClickChat}>
                            {domToReact(targetChildren, options)}
                        </span>
                        {domToReact(remainingChildren, options)}
                    </>
                );
            } else if (attribs.id === 'phoneNumber') {
                return <a href={contactPhoneURL}>{contactNumber}</a>;
            }
        },
    };

    const flexClasses = classNames(styles.flexContainer, {
        [styles.RIGHT]: alignCarousel === ALIGN_CAROUSEL.RIGHT,
        [styles.LEFT]: alignCarousel === ALIGN_CAROUSEL.LEFT,
    });

    const happyfaceEmoji = getCloudinaryImageUrl(emoji);

    if (isMobile) {
        return <CarouselBlockMobile onClickBanner={onClickBanner} {...props} />;
    }

    return (
        <BackgroundBlock id={CAROUSEL_BLOCK}>
            <div className={styles.container}>
                <Slider {...settings}>
                    {carouselCards.map(card => {
                        const imageURL = getCloudinaryImageUrl(
                            card.carouselImage
                        );

                        return (
                            <div key={card.id}>
                                <div className={flexClasses}>
                                    {imageURL && (
                                        <img
                                            className={styles.imageContainer}
                                            src={imageURL}
                                            alt={card.carouselImage.name}
                                        />
                                    )}

                                    <div className={styles.cardDetails}>
                                        {emoji && (
                                            <img
                                                className={
                                                    styles.happyfaceEmoji
                                                }
                                                src={happyfaceEmoji}
                                                alt="emoji"
                                            />
                                        )}

                                        {card.title && (
                                            <h1 className={styles.title}>
                                                {card.title}
                                            </h1>
                                        )}

                                        {card.content && (
                                            <div className={styles.content}>
                                                {card.content.content}
                                            </div>
                                        )}

                                        {card.items && (
                                            <ul className={styles.items}>
                                                {card.items.map((list, i) => (
                                                    <li key={i}>{list}</li>
                                                ))}
                                            </ul>
                                        )}

                                        <div className={styles.btnItems}>
                                            <div>
                                                {card.ctaAppleStore && (
                                                    <button
                                                        id="ctaAppleStoreBanner"
                                                        aria-label="Apple App Store app link"
                                                        className={classNames(
                                                            styles.ctaButton,
                                                            styles.appleStore
                                                        )}
                                                        onClick={() =>
                                                            onClickBanner(
                                                                card
                                                                    ?.ctaAppleStore
                                                                    ?.url
                                                            )
                                                        }
                                                    />
                                                )}

                                                {card.ctaGooglePlay && (
                                                    <button
                                                        id="ctaGooglePlayBanner"
                                                        aria-label="Google Play Store app link"
                                                        className={classNames(
                                                            styles.ctaButton,
                                                            styles.googlePlay
                                                        )}
                                                        onClick={() =>
                                                            onClickBanner(
                                                                card
                                                                    ?.ctaGooglePlay
                                                                    ?.url
                                                            )
                                                        }
                                                    />
                                                )}
                                            </div>

                                            {card.ctaButton && (
                                                <CTAButton
                                                    className={styles.ctaBtn}
                                                    {...card.ctaButton}
                                                />
                                            )}
                                        </div>

                                        {card.additionalContent && (
                                            <div className={styles.content}>
                                                {
                                                    card.additionalContent
                                                        .additionalContent
                                                }
                                            </div>
                                        )}

                                        <div className={styles.contactInfo}>
                                            {card.contactInfo &&
                                                htmlStringReactParser(
                                                    card.contactInfo
                                                        .childMarkdownRemark
                                                        .html,
                                                    options
                                                )}
                                        </div>

                                        <div className={styles.disclaimer}>
                                            {card.disclaimer &&
                                                renderRichText(card.disclaimer)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </Slider>
            </div>
        </BackgroundBlock>
    );
};

export default CarouselBlock;

export const query = graphql`
    fragment CarouselBlock on ContentfulCarouselBlock {
        id
        name
        alignCarousel
        emoji {
            ...CloudinaryImage
        }
        carouselCards {
            id
            name
            title
            contactInfo {
                contactInfo
                childMarkdownRemark {
                    html
                }
            }
            ctaButton {
                ...CTAButton
            }
            ctaAppleStore {
                ...CTAButton
            }
            ctaGooglePlay {
                ...CTAButton
            }
            content {
                content
            }
            items
            carouselImage {
                ...CloudinaryImage
            }
            additionalContent {
                additionalContent
            }
            disclaimer {
                raw
            }
        }
    }
`;
