// extracted by mini-css-extract-plugin
export var CHECK_MARK = "ProgramBenefitsBlock-module--CHECK_MARK--dJYii";
export var NUMBERING = "ProgramBenefitsBlock-module--NUMBERING--3e9rD";
export var benefitContainer = "ProgramBenefitsBlock-module--benefit-container--INNEU";
export var bulletFormat = "ProgramBenefitsBlock-module--bullet-format--eNctm";
export var bulletType = "ProgramBenefitsBlock-module--bullet-type--zhQLK";
export var circle = "ProgramBenefitsBlock-module--circle--6V4tf";
export var content = "ProgramBenefitsBlock-module--content--XoMej";
export var contentDetails = "ProgramBenefitsBlock-module--content-details--xdAO2";
export var ctaButton = "ProgramBenefitsBlock-module--cta-button--FRRLz";
export var digit = "ProgramBenefitsBlock-module--digit--dZ3QW";
export var disclaimer = "ProgramBenefitsBlock-module--disclaimer--chHl5";
export var image = "ProgramBenefitsBlock-module--image--y0xhP";
export var kick = "ProgramBenefitsBlock-module--kick--Vh+c3";
export var leftSide = "ProgramBenefitsBlock-module--left-side--NMt05";
export var programName = "ProgramBenefitsBlock-module--program-name--rgjri";
export var rightSide = "ProgramBenefitsBlock-module--right-side---6zAR";
export var root = "ProgramBenefitsBlock-module--root--iwm5I";
export var sentenceFormat = "ProgramBenefitsBlock-module--sentence-format--hemGe";
export var smallText = "ProgramBenefitsBlock-module--small-text--ggDYY";
export var stem = "ProgramBenefitsBlock-module--stem--eROSW";
export var title = "ProgramBenefitsBlock-module--title--mDXg0";