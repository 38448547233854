// extracted by mini-css-extract-plugin
export var cardItem = "TestimonialsBlock-module--card-item--ei7oG";
export var content = "TestimonialsBlock-module--content--6vwvp";
export var memberImage = "TestimonialsBlock-module--member-image--bIZ6v";
export var memberName = "TestimonialsBlock-module--member-name--kdAMB";
export var root = "TestimonialsBlock-module--root--zy77V";
export var subtitle = "TestimonialsBlock-module--subtitle--ozUvT";
export var testimonial = "TestimonialsBlock-module--testimonial--eatxt";
export var testimonialCards = "TestimonialsBlock-module--testimonial-cards--XPNSC";
export var testimonialsDisclaimer = "TestimonialsBlock-module--testimonials-disclaimer--WX9a2";
export var title = "TestimonialsBlock-module--title--RJDYZ";