// extracted by mini-css-extract-plugin
export var content = "VideoBlock-module--content--YG2CR";
export var contentContainer = "VideoBlock-module--content-container--f1qGJ";
export var disclaimer = "VideoBlock-module--disclaimer--Z4JH7";
export var image = "VideoBlock-module--image--d3+cu";
export var isMobile = "VideoBlock-module--is-mobile--VttoG";
export var leftSide = "VideoBlock-module--left-side--+MUpN";
export var quoteContainer = "VideoBlock-module--quote-container--3ybYv";
export var root = "VideoBlock-module--root--FE6Dm";
export var rootCentered = "VideoBlock-module--rootCentered--1u6kd";
export var title = "VideoBlock-module--title--mewFa";
export var videoContainer = "VideoBlock-module--video-container--5Az9E";