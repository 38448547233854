import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {SUPPLY_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import CTAButton from 'components/ctaButton';
import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './SupplyBlock.module.scss';

const SupplyBlock = props => {
    const {
        additionalContent,
        backgroundBlock,
        content,
        ctaButton,
        disclaimer,
        items,
        subtitle,
        title,
    } = props;
    const {isMobile} = useWindowSize();

    return (
        <BackgroundBlock id={SUPPLY_BLOCK} {...backgroundBlock}>
            <div className={classNames(styles.root)}>
                {subtitle && <div className={styles.subtitle}>{subtitle}</div>}

                {title && <div className={styles.title}>{title}</div>}

                <div
                    className={classNames(styles.content, {
                        [styles.hasButtonBelow]: !!ctaButton,
                    })}
                >
                    {content?.content}
                </div>

                {ctaButton && (
                    <CTAButton
                        id={classNames(styles.ctaButton)}
                        isBold
                        isMobile={isMobile}
                        {...ctaButton}
                    ></CTAButton>
                )}

                {items && (
                    <ul className={styles.items}>
                        {items.map((item, i) => {
                            return <li key={i}>{item}</li>;
                        })}
                    </ul>
                )}

                {additionalContent && (
                    <div className={classNames(styles.content)}>
                        {additionalContent.additionalContent}
                    </div>
                )}

                {disclaimer && (
                    <div className={styles.disclaimer}>
                        {renderRichText(disclaimer)}
                    </div>
                )}
            </div>
        </BackgroundBlock>
    );
};

export default SupplyBlock;

// Had to use an alias with content because it was conflicting type with other content on other blocks, should probably convert to Rich Text Type
export const query = graphql`
    fragment SupplyBlock on ContentfulSupplyBlock {
        id
        name
        title
        subtitle
        content {
            content
        }
        disclaimer {
            raw
        }
        items
        additionalContent {
            additionalContent
        }
        ctaButton {
            ...CTAButton
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
