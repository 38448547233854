import React from 'react';
import {graphql} from 'gatsby';

import {BREAKING_DOWN_BARRIERS_BLOCK} from 'constants/blockNames';

import {getCloudinaryImageUrl} from 'utils/utilities';

import * as styles from './BreakingDownBarriersBlock.module.scss';

// We can probably improve
const defaultBackgroundImageUrl =
    'https://res.cloudinary.com/livongo-health-inc/image/upload/v1602666635/contentful/Block%20Backgrounds/meter-bg.jpg';

const BreakingDownBarriersBlock = props => {
    const {title, listItemsWithImages} = props;

    return (
        <div id={BREAKING_DOWN_BARRIERS_BLOCK} className={styles.root}>
            <div className={styles.container}>
                <div className={styles.title}>{title}</div>
                <div className={styles.listItems}>
                    {listItemsWithImages &&
                        listItemsWithImages.map(item => {
                            const iconURL =
                                getCloudinaryImageUrl(item.image) ||
                                defaultBackgroundImageUrl;
                            return (
                                <div key={item.id} className={styles.listItem}>
                                    {iconURL && (
                                        <img src={iconURL} alt={item.image.name}/>
                                    )}
                                    <div className={styles.contentTitle}>
                                        {item.title}
                                    </div>
                                    <div className={styles.content}>
                                        {item.content}
                                    </div>
                                </div>
                            );
                        })}
                </div>
            </div>
        </div>
    );
};

export default BreakingDownBarriersBlock;

export const query = graphql`
    fragment BreakingDownBarriersBlock on ContentfulBreakingDownBarriersBlock {
        id
        name
        title
        listItemsWithImages {
            id
            content
            name
            title
            image {
                ...CloudinaryImage
            }
        }
    }
`;
