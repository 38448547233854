// extracted by mini-css-extract-plugin
export var LG = "HeroBlock-module--LG--XFrDM";
export var MD = "HeroBlock-module--MD--2Ptlf";
export var contactInfo = "HeroBlock-module--contact-info--Zmsw1";
export var container = "HeroBlock-module--container--gDiGo";
export var content = "HeroBlock-module--content--+rFd5";
export var ctaBtn = "HeroBlock-module--cta-btn--u8ZG+";
export var desktopView = "HeroBlock-module--desktop-view--oa1jp";
export var heroImage = "HeroBlock-module--hero-image--bZfSi";
export var horizontally = "HeroBlock-module--horizontally--HACMJ";
export var mobileHeroBanner = "HeroBlock-module--mobile-hero-banner--G6zus";
export var mobileHeroContainer = "HeroBlock-module--mobile-hero-container--juXUC";
export var mobileView = "HeroBlock-module--mobile-view--ZvK+j";
export var pipeline = "HeroBlock-module--pipeline--XwMnZ";
export var subContainer = "HeroBlock-module--sub-container--Os6Rk";
export var title = "HeroBlock-module--title--DkwjQ";
export var vertically = "HeroBlock-module--vertically--+Xu+W";
export var videoButton = "HeroBlock-module--videoButton--HP9uk";