import React from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';

import {GUIDELINE_BLOCK} from 'constants/blockNames';

import BackgroundBlock from 'components/backgroundBlock';

import * as styles from './GuidelinesBlock.module.scss';

const GuidelinesBlock = props => {
    const {guideLines, backgroundBlock} = props;

    return (
        <BackgroundBlock id={GUIDELINE_BLOCK} {...backgroundBlock}>
            {guideLines.map((obj, i) => {
                return (
                    <div key={i} className={classNames(styles.guideContainer)}>
                        <h1>{obj.title}</h1>
                        <div>{renderRichText(obj.content)}</div>
                    </div>
                );
            })}
        </BackgroundBlock>
    );
};

export default GuidelinesBlock;

export const query = graphql`
    fragment GuidelinesBlock on ContentfulGuidelinesBlock {
        id
        name
        guideLines {
            title
            content {
                raw
            }
        }
        backgroundBlock {
            ...BackgroundBlock
        }
    }
`;
