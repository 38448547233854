import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import useWindowSize from 'hook/useWindowSize';
import {getCloudinaryObject, removeNullFromProps} from 'utils/utilities';

import * as styles from './BackgroundBlock.module.scss';

const MOBILE_BACKGROUND_IMAGE_ORIENTATION = {
    TOP: 'Top',
    CENTER: 'Center',
    BOTTOM: 'Bottom',
    NONE: 'None',
};

const WHITE = '#FFFFFF';
const BLACK = '#000000';

const MobileBackgroundBlock = props => {
    const {
        id,
        className,
        containerClassName,
        children,
        backgroundColorHex = WHITE,
        backgroundGradient,
        textColorHex = BLACK,
        backgroundImage,
        mobileBackgroundImage,
        mobileBackgroundOrientation = MOBILE_BACKGROUND_IMAGE_ORIENTATION.TOP,
        mobileBackgroundImageMaxWidth,
        mobileBackgroundImagePadding = 0,
    } = removeNullFromProps(props);

    const {width: containerWidth} = useWindowSize();
    const {
        url: backgroundImageURL,
        width: defaultImageWidth,
        height: imageHeight,
    } =
        getCloudinaryObject(mobileBackgroundImage) ||
        getCloudinaryObject(backgroundImage) ||
        {};

    let topContainerStyle = {};
    let centerContainerStyle = {};
    let bottomContainerStyle = {};
    let backgroundStyle = {};

    if (backgroundImageURL) {
        // Get the smallest width for the image out of container width, actually image width, or mobileBackgroundImageMaxWidth if set
        let imageWidth = containerWidth - 2 * mobileBackgroundImagePadding;

        if (imageWidth > defaultImageWidth) {
            imageWidth = defaultImageWidth;
        }
        if (
            mobileBackgroundImageMaxWidth &&
            imageWidth > mobileBackgroundImageMaxWidth
        ) {
            imageWidth = mobileBackgroundImageMaxWidth;
        }

        // Figure out the image size and the image container size
        const backgroundStyle = {
            backgroundImage: `url(${backgroundImageURL})`,
            backgroundSize: `${imageWidth}px auto`,
            backgroundPosition: `center ${mobileBackgroundImagePadding}px`,
            backgroundRepeat: 'no-repeat',
            height:
                imageHeight * (imageWidth / defaultImageWidth) +
                mobileBackgroundImagePadding * 2,
            width: '100%',
        };

        switch (mobileBackgroundOrientation) {
            case MOBILE_BACKGROUND_IMAGE_ORIENTATION.TOP:
                topContainerStyle = backgroundStyle;
                break;

            case MOBILE_BACKGROUND_IMAGE_ORIENTATION.CENTER:
                centerContainerStyle = {
                    backgroundImage: `url(${backgroundImageURL})`,
                    backgroundSize: `cover`,
                    backgroundRepeat: 'no-repeat',
                };
                break;

            case MOBILE_BACKGROUND_IMAGE_ORIENTATION.BOTTOM:
                bottomContainerStyle = backgroundStyle;
                break;
        }
    }

    if (backgroundGradient) {
        backgroundStyle['backgroundImage'] = backgroundGradient;
        backgroundStyle['color'] = textColorHex;
    } else {
        backgroundStyle['backgroundColor'] = backgroundColorHex;
        backgroundStyle['color'] = textColorHex;
    }

    return (
        <div
            id={id}
            className={classNames(styles.root, className)}
            style={backgroundStyle}
        >
            <div style={topContainerStyle}></div>
            <div
                className={classNames(
                    styles.mobileContainer,
                    containerClassName
                )}
                style={centerContainerStyle}
            >
                {children}
            </div>
            <div style={bottomContainerStyle}></div>
        </div>
    );
};

MobileBackgroundBlock.propTypes = {
    id: PropTypes.string.isRequired,
    className: PropTypes.string,
    textColorHex: PropTypes.string,
    backgroundColorHex: PropTypes.string,
    backgroundImage: PropTypes.object,
    backgroundGradient: PropTypes.string,
    mobileBackgroundImage: PropTypes.object,
    mobileBackgroundOrientation: PropTypes.string,
    mobileBackgroundImageMaxWidth: PropTypes.number,
    mobileBackgroundImagePadding: PropTypes.number,
};

export default MobileBackgroundBlock;
