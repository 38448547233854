import React, {useRef, useEffect, useState} from 'react';
import classNames from 'classnames';
import {graphql} from 'gatsby';
import {renderRichText} from 'gatsby-source-contentful/rich-text';
import {VIDEO_BLOCK} from 'constants/blockNames';
import useWindowSize from 'hook/useWindowSize';
import CTAButton from 'components/ctaButton';
import BackgroundBlock from 'components/backgroundBlock';
import * as styles from './VideoBlock.module.scss';

const YOUTUBE_H_TO_W_RATIO = 360 / 640;

const VideoBlock = props => {
    const {
        titleRichText,
        mainTitle,
        content,
        quoteText,
        videoUrl,
        ctaButton,
        disclaimer,
        backgroundBlock,
        designType,
    } = props;

    const ref = useRef(null);
    const {isMobile} = useWindowSize();
    const [containerWidth, setContainerWidth] = useState(null);
    const isVideoDesignCentered = designType && designType[0] === 'centered';

    useEffect(() => {
        if (ref.current) {
            setContainerWidth(ref.current.offsetWidth);
        }
        const handleResize = () => {
            setContainerWidth(ref.current.offsetWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [ref.current]);

    return (
        <BackgroundBlock id={VIDEO_BLOCK} {...backgroundBlock}>
            {isVideoDesignCentered ? (
                <div
                    className={classNames(styles.rootCentered, {
                        [styles.isMobile]: isMobile,
                    })}
                >
                    {titleRichText && (
                        <div className={styles.title}>
                            {renderRichText(titleRichText)}
                        </div>
                    )}
                    {mainTitle && (
                        <div className={styles.title}>{mainTitle}</div>
                    )}
                    {content && (
                        <div className={classNames(styles.content)}>
                            {renderRichText(content)}
                        </div>
                    )}

                    <div className={styles.contentContainer}>
                        <div ref={ref} className={styles.videoContainer}>
                            {containerWidth && (
                                <iframe
                                    src={videoUrl}
                                    width={'100%'}
                                    height={
                                        containerWidth * YOUTUBE_H_TO_W_RATIO
                                    }
                                    allow="autoplay; fullscreen"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                            {disclaimer && (
                                <div className={styles.disclaimer}>
                                    {renderRichText(disclaimer)}
                                </div>
                            )}
                        </div>
                        {quoteText && (
                            <div className={styles.quoteContainer}>
                                {renderRichText(quoteText)}
                            </div>
                        )}
                    </div>
                    {ctaButton && (
                        <CTAButton id="ctaButton" {...ctaButton}></CTAButton>
                    )}
                </div>
            ) : (
                <div
                    className={classNames(styles.root, {
                        [styles.isMobile]: isMobile,
                    })}
                >
                    <div className={styles.leftSide}>
                        {titleRichText && (
                            <div className={styles.title}>
                                {renderRichText(titleRichText)}
                            </div>
                        )}
                        {mainTitle && (
                            <div className={styles.title}>{mainTitle}</div>
                        )}
                        {content && (
                            <div className={classNames(styles.content)}>
                                {renderRichText(content)}
                            </div>
                        )}
                        {ctaButton && (
                            <CTAButton
                                id="ctaButton"
                                {...ctaButton}
                            ></CTAButton>
                        )}
                    </div>

                    <div className={styles.contentContainer}>
                        <div ref={ref} className={styles.videoContainer}>
                            {containerWidth && (
                                <iframe
                                    src={videoUrl}
                                    width="640"
                                    height="360"
                                    allow="autoplay; fullscreen"
                                    title="YouTube video player"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen
                                ></iframe>
                            )}
                            {disclaimer && (
                                <div className={styles.disclaimer}>
                                    {renderRichText(disclaimer)}
                                </div>
                            )}
                        </div>
                        {quoteText && (
                            <div className={styles.quoteContainer}>
                                {renderRichText(quoteText)}
                            </div>
                        )}
                    </div>
                </div>
            )}
        </BackgroundBlock>
    );
};

export default VideoBlock;

export const query = graphql`
    fragment VideoBlock on ContentfulVideoBlock {
        name
        videoUrl
        mainTitle
        titleRichText: title {
            raw
        }
        content {
            raw
        }
        ctaButton {
            ...CTAButton
        }
        quoteText {
            raw
        }
        disclaimer {
            raw
        }
        backgroundBlock {
            ...BackgroundBlock
        }
        designType
    }
`;
